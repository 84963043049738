import { mapHelper } from 'common/utils'
// 是否顶层组织
const isTop = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: isTopMap,
  setOps: isTopOps
} = mapHelper.setMap(isTop)

// 组织状态
const status = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 2
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 数据来源
const source = [
  {
    text: '后台录入',
    value: 1
  },
  {
    text: 'app申请',
    value: 2
  },
  {
    text: '组织中心创建',
    value: 3
  },
  {
    text: '家庭类组织自动创建',
    value: 4
  }
]

const {
  map: sourceMap,
  setOps: sourceOps
} = mapHelper.setMap(source)

// 是否顶层组织
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)

// 区域类型
const searchRegionType = [
  {
    text: '分子公司',
    value: 0
  },
  {
    text: '园区公司分公司',
    value: 1
  },
  {
    text: '管理项目组',
    value: 2
  }
]

const {
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)

export {
  isTopMap,
  isTopOps,
  statusMap,
  statusOps,
  sourceMap,
  sourceOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeOps
}
