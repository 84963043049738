<template>
  <div class="party-mgr-list-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入" permission="import" @click="importClick"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="组织名称" v-model="searchParams.orgName"></v-input>
        <v-select label="是否顶层组织" v-model="searchParams.isTop" :options="isTopOps"></v-select>
        <v-select2 label="顶层组织" v-model="searchParams.topId" v-bind="topOrgParams"></v-select2>
        <v-select label="组织状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker
          label="创建时间"
          type="rangedatetimer"
          format="YYYY-MM-DD HH:mm:ss"
          :maxDate="maxDate"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="查看成员" type="text" permission="viewMember" @click="viewMember(scope.row)"></v-button>
        <v-button v-if="isCommunityUser" text="搬离" type="text" permission="moveOut" @click="moveOutHandle(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgTagListURL,
  getUserRoomsURL,
  getOrgManagerURL,
  getOrgListURL
} from './api'
import {
  isTopOps,
  statusOps,
  sourceOps
} from './map'
import { communityParams, regionParams } from 'common/select2Params'
import { createHTMLVNode } from 'common/vdom'
import moment from 'moment'

export default {
  name: 'partyMgrList',
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      isTopOps: isTopOps(1),
      statusOps: statusOps(1),
      sourceOps: sourceOps(1),
      orgTagParams: {
        searchUrl: getOrgTagListURL,
        request: {
          text: 'tagName',
          value: 'id'
        },
        response: {
          text: 'tagName',
          value: 'id'
        },
        subjoin: {
          isPartyTag: 1
        }
      },
      communityParams: communityParams,
      regionParams: regionParams,
      userRoomsgParams: {
        searchUrl: getUserRoomsURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        method: 'POST'
      },
      orgManagerParams: {
        searchUrl: getOrgManagerURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      topOrgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      searchParams: {
        orgName: '',
        tagId: '',
        communityId: '',
        houseId: '',
        isTop: undefined,
        managerId: '',
        topId: '',
        status: undefined,
        source: undefined,
        startTime: '',
        endTime: '',
        regionId: '',
        contractBeginStartTime: '',
        contractBeginStopTime: '',
        contractEndStartTime: '',
        contractEndStopTime: '',
        centerType: 1,
        isPartyOrg: 1,
        curOrgIds: ''
      },
      headers: [
        {
          prop: 'orgName',
          label: '组织名称'
        },
        {
          prop: 'communityNameVNode',
          label: '关联项目',
          formatter: (row, prop) => {
            let communityNameVNode = row.communityName && row.communityName.length ? row.communityName.join('<br>') : ''
            return createHTMLVNode(this, communityNameVNode)
          }
        },
        {
          prop: 'top',
          label: '是否顶层组织'
        },
        {
          prop: 'topParentName',
          label: '顶层组织'
        },
        {
          prop: 'status',
          label: '组织状态'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '最近操作时间'
        }
      ]
    }
  },
  computed: {
    isCommunityUser () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && userInfo.userType === '101') {
          show = true
        }
      }
      return show
    },

    showRegion () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    },
    curOrgIds () {
      return this.$store.state.curOrgIds && this.$store.state.curOrgIds.join(',')
    }
  },
  created () {
    console.log(this.$store.state.permission)
    this.searchParams.curOrgIds = this.curOrgIds
  },
  mounted () {
    const { houseId } = this.$route.query
    if (houseId !== undefined) {
      this.searchParams.houseId = houseId
    }
  },
  methods: {
    importClick () {
      this.$router.push({
        name: 'orgManageFormImport'
      })
    },
    create () {
      this.$router.push({
        name: 'orgManageForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'partyMgrForm',
        query: {
          id: row.id,
          edit: true
        }
      })
    },

    viewMember (row) {
      this.$router.push({
        name: 'partyMemberInformation',
        query: {
          id: row.id,
          page: 'orgMgr'
        }
      })
    }
  },
  watch: {
    '$store.state.curOrgIds' () {
      this.searchParams.curOrgIds = this.curOrgIds
      this.$refs.list.searchData()
    }
  }
}
</script>
