
const getListURL = `${API_CONFIG.butlerBaseURL}orgInfo/organizations`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}orgInfo/exportPartyOrganizationList`
// 获取组织标签列表
const getOrgTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 获取房号列表
const getUserRoomsURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 获取组织超管列表
const getOrgManagerURL = `${API_CONFIG.butlerBaseURL}user/search/keyword`
// 获取顶层组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/organization/searchOrg`
// 搬离组织
const removeOrgURL = `${API_CONFIG.butlerBaseURL}orgInfo/delCommunity/`
// 获取组织详情
const getOrgDetailURL = `${API_CONFIG.butlerBaseURL}orgInfo/organization/detail`
// 获取组织授权信息
const getOrgAuthInfoURL = `${API_CONFIG.butlerBaseURL}orgStruct/authStrucrUserList`
// 更新组织授权信息
const updateOrgAuthInfoURL = `${API_CONFIG.butlerBaseURL}orgStruct/updateAuthStructUser`
// 新增组织
const addOrgURL = `${API_CONFIG.butlerBaseURL}orgInfo/organization`
// 校验组织
const checkOrgURL = `${API_CONFIG.butlerBaseURL}orgInfo/organization/check`
// 获取一级标签
const getAllSuperiorTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getAllSuperiorTag`
// 获取一级标签下的二级标签
const getOrgTagByParentIdURL = `${API_CONFIG.butlerBaseURL}orgTag/getOrgTagByParentId/`
// 获取组织标签属性
const getTagAttributeURL = `${API_CONFIG.butlerBaseURL}orgTag/getTagAttributeListByOrgTagId/`
// 文件上传地址
const uploadFileURL = `${API_CONFIG.uploadURL}?module=`
// 项目多选地址
const communityMultiSelectURL = `${API_CONFIG.baseURL}serverCommunityAction!getCommunityList.action?ismodal=1`
// 房号多选地址
const houseMultiSelectURL = `${API_CONFIG.baseURL}serverOwnerAction!getRoomList.action`
// 获取运营项目组地址
const getOperateRegionIdUrl = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取项目类型地址
const getCommunityTypeUrl = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 获取项目阶段状态地址
const getCommunityStageUrl = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 获取组织成员地址
const getOrgMemberMultiSelectUrl = `${API_CONFIG.butlerBaseURL}orgStruct/searchStrucrUserInfo`
// 查询组织成员地址
const getOrgMemberUrl = `${API_CONFIG.butlerBaseURL}user/search/keyword`
// 组织导入
const orgImportURL = `${API_CONFIG.butlerBaseURL}orgInfo/import/orgInfo`

// 查询党员信息列表
const getAuditURL = `${API_CONFIG.shopBaseURL}partyMemberInfo/getPartyMemberAuthApproveList`

// 获取补充信息属性为下拉项的选项
const getSelectOpsURL = `${API_CONFIG.butlerBaseURL}orgInfo/getOrgTagSelect`

export {
  getListURL,
  exportListURL,
  getOrgTagListURL,
  getUserRoomsURL,
  getOrgManagerURL,
  getOrgListURL,
  removeOrgURL,
  getOrgDetailURL,
  getOrgAuthInfoURL,
  updateOrgAuthInfoURL,
  addOrgURL,
  checkOrgURL,
  getAllSuperiorTagURL,
  getOrgTagByParentIdURL,
  getTagAttributeURL,
  uploadFileURL,
  communityMultiSelectURL,
  houseMultiSelectURL,
  getOperateRegionIdUrl,
  getCommunityTypeUrl,
  getCommunityStageUrl,
  getOrgMemberMultiSelectUrl,
  getOrgMemberUrl,
  orgImportURL,
  getAuditURL,
  getSelectOpsURL
}
